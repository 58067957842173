var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-6" },
      [
        _c("c-table", {
          ref: "machineGrid",
          attrs: {
            title: "유해위험기계기구",
            columns: _vm.machineGrid.columns,
            gridHeight: "400px",
            data: _vm.process.macinerys,
            columnSetting: false,
            isFullScreen: false,
            usePaging: false,
            expandAll: false,
            filtering: false,
            isExcelDown: false,
            hideBottom: true,
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-6" },
      [
        _c("c-table", {
          ref: "chemGrid",
          attrs: {
            title: "유해화학물질",
            columns: _vm.chemGrid.columns,
            gridHeight: "400px",
            data: _vm.process.chemlist,
            columnSetting: false,
            isFullScreen: false,
            usePaging: false,
            expandAll: false,
            filtering: false,
            isExcelDown: false,
            hideBottom: true,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }