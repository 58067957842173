<template>
  <div class="row">
    <div class="col-6">
      <c-table
        ref="machineGrid"
        title="유해위험기계기구"
        :columns="machineGrid.columns"
        gridHeight="400px"
        :data="process.macinerys"
        :columnSetting="false"
        :isFullScreen="false"
        :usePaging="false"
        :expandAll="false"
        :filtering="false"
        :isExcelDown="false"
        :hideBottom="true"
      >
      </c-table>
    </div>
    <div class="col-6">
      <c-table
        ref="chemGrid"
        title="유해화학물질"
        :columns="chemGrid.columns"
        gridHeight="400px"
        :data="process.chemlist"
        :columnSetting="false"
        :isFullScreen="false"
        :usePaging="false"
        :expandAll="false"
        :filtering="false"
        :isExcelDown="false"
        :hideBottom="true"
      >
      </c-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'fm-target-process',

  props: {
    assessPlan: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        ramStepCd: '',
        ramTechniqueCd: '',  // 평가기법 코드_HAZOP/KRAS/JSA/CHECKLIST
        processes: [], // 대상공정 목록
      }),
    },
    process: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        processCd: '',  // 단위공정 코드
        processName: '',  // 단위공정명
        ramProcessAssessStepCd: '',  // 평가진행상태
        processExplainFeature: '',  // 공정 특징 설명
        assessDeptCd: '',  // 평가 부서 코드
        assessUserId: '',  // 평가 담당자
        regUserId: '',  // 작성자 ID
        chgUserId: '',  // 수정자 ID
        equipments: [],
        fmScenarios: [],
        macinerys: [],
        chemlist: [],
      }),
    },
    disabled: {
      type: Boolean,
      default: function() {
        return false;
      }
    },
    updateBtnData: {
      type: Object,
      default: () => ({
        flag: false,
        research: '',
      }),
    },
  },
  data() {
    return {
      editable: false,
      machineGrid: {
        columns: [
          {
            name: 'machineryClassificationName',
            field: 'machineryClassificationName',
            label: '기계분류',
            align: 'center',
            sortable: true,
          },
          {
            name: 'machineryName',
            field: 'machineryName',
            label: '기계명',
            align: 'center',
            sortable: true,
          },
          {
            name: 'cnt',
            field: 'cnt',
            label: '수량',
            align: 'right',
            sortable: true,
          },
        ],
      },
      chemGrid: {
        columns: [
          {
            name: 'chemNm',
            field: 'chemNm',
            label: '화학물질명',
            align: 'center',
            style: 'width:60%',
            sortable: true,
          },
          {
            name: 'useQty',
            field: 'useQty',
            label: '취급량',
            align: 'right',
            // type: 'number',
            style: 'width:20%',
            sortable: true,
          },
          {
            name: 'useTime',
            field: 'useTime',
            label: '취급시간',
            align: 'right',
            // type: 'number',
            style: 'width:20%',
            sortable: true,
          },
        ],
      },
      listUrl: '',
      listUrl2: '',
      processInfo: {
        plantCd: null,
        processCd: '',
        processNo: '',
        processName: '',
        upProcessCd: '',
        upProcessName: '',
        processLevelCd: '',
        processDesc: '',
        managementDepts: '',
        managementDeptsName: '',
        orderNo: '',
        mainMaterial: '',
        workerCnt: '',
        productName: '',
        useFlag: 'Y',
        psmFlag: 'N',
        workCycleCd: null,
        workingTime: null,
        temperature: '',
        macinerys: [],
        chemlist: [],
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
    },
  }
};
</script>
